.masthead {
    min-height: 30rem;
    position: relative;
    display: table;
    width: 100%;
    height: auto;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.masthead h1,
.masthead .h1 {
    font-size: 10vw;
    margin: 0;
    padding: 0;
}

.masthead h3,
.masthead .h3 {
    font-size: 3vw;
    margin: 0;
    padding: 0;
}

@media (min-width: 992px) {
    .masthead {
        height: 100vh;
    }

    .masthead h1,
    .masthead .h1 {
        font-size: 5.5rem;
    }

    .masthead h3,
    .masthead .h3 {
        font-size: 1.5rem;
        margin: 0;
        padding: 0;
    }
}

.btn-xl {
    padding: 1.25rem 2.5rem;
}

.video-container {
    width: 100%;
    height: 70vh;
    position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.video-container .caption {
  z-index: 1;
}

.custom-shadow {
    color: #202020;
    letter-spacing: .1em;
    text-shadow: -1px -1px 1px #1D809F, 2px 2px 1px #6FB3B8;
}
