.content-section-heading h2,
.content-section-heading .h2 {
    font-size: 3rem;
}

.content-section-heading h3,
.content-section-heading .h3 {
    font-size: 1rem;
    text-transform: uppercase;
}

caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left;
  }

.caption-heading {
    font-size : 2rem;
}



.portfolio-item {
    display: block;
    position: relative;
    overflow: hidden;
    max-width: 530px;
    height: 500px;
    margin: auto auto 1rem;
}

.portfolio-item .caption {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(33, 37, 41, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.portfolio-item .caption .caption-content {
    color: #fff;
    margin: auto 2rem 2rem;
    width: 100%;
}

.portfolio-item .caption .caption-content h2,
.portfolio-item .caption .caption-content .h2 {
    font-size: 0.8rem;
    text-transform: uppercase;
}

.portfolio-item .caption .caption-content p {
    font-weight: 300;
    font-size: 1.2rem;
}

@media (min-width: 992px) {
    .portfolio-item {
        max-width: none;
        margin: 0;
    }

    .portfolio-item .caption {
        transition: background-color 0.7s, -webkit-clip-path 0.25s ease-out;
        transition: clip-path 0.25s ease-out, background-color 0.7s;
        transition: clip-path 0.25s ease-out, background-color 0.7s, -webkit-clip-path 0.25s ease-out;
        -webkit-clip-path: inset(0px);
        clip-path: inset(0px);
    }

    .portfolio-item .caption .caption-content {
        transition: opacity 0.25s;
        margin-left: 4rem;
        margin-right: 4rem;
        margin-bottom: 2rem;
    }

    .portfolio-item img {
        transition: -webkit-clip-path 0.25s ease-out;
        transition: clip-path 0.25s ease-out;
        transition: clip-path 0.25s ease-out, -webkit-clip-path 0.25s ease-out;
        -webkit-clip-path: inset(-1px);
        clip-path: inset(-1px);
    }

    .portfolio-item:hover img {
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem);
    }

    .portfolio-item:hover .caption {
        background-color: rgba(86, 84, 84, 0.332);
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem);
    }
}
