section.quote {
    position: relative;
    overflow: hidden;
    /* background: linear-gradient(0deg, #ff6a00 0%, #ee0979 100%); */
    background: linear-gradient(0deg, #1D809F 0%, #6FB3B8 100%);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
  }
  section.quote .quote-content {
    z-index: 1;
    position: relative;
  }
  section.quote .quote-content .quote-heading {
    font-size: 4rem;
  }
  section.quote .quote-content .quote-subheading {
    font-size: 2rem;
  }
  section.quote .bg-circle {
    z-index: 0;
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(0deg, #6FB3B8 0%, #1D809F 100%);
  }
  section.quote .bg-circle-1 {
    height: 90rem;
    width: 90rem;
    bottom: -55rem;
    left: -55rem;
  }
  section.quote .bg-circle-2 {
    height: 50rem;
    width: 50rem;
    top: -25rem;
    right: -25rem;
  }
  section.quote .bg-circle-3 {
    height: 20rem;
    width: 20rem;
    bottom: -10rem;
    right: 5%;
  }
  section.quote .bg-circle-4 {
    height: 30rem;
    width: 30rem;
    top: -5rem;
    right: 35%;
  }

  @media (min-width: 992px) {
    section.quote .quote-content .quote-heading {
      font-size: 6rem;
    }
    section.quote .quote-content .quote-subheading {
      font-size: 4rem;
    }
  }
