.social-link {
    display: block;
    text-decoration: none;
    height: 2rem;
    width: 2rem;
    line-height: 2.3rem;
    font-size: 1rem;
    background: #2398bc;
    /* background-color: #1D809F; */
    transition: background-color 0.15s ease-in-out;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.social-link:hover {
    background-color: #155d74;
    text-decoration: none;
}
