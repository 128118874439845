.navbar-custom {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: rgba(2, 45, 58, 0.482);
  }
  .navbar-custom .navbar-brand {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
  }
  .navbar-custom .navbar-nav .nav-item .nav-link {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
  }
